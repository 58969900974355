
<template>

<div id="app">
<div class="row">
<div class="card">
<Demo />
</div>
<div class="card">
<Assignment />
<Timeline />
<Team />
<Chat />
<Assignment />
</div>
</div>
<div class="row">
<div class="card">
<Configurator />
</div>
<div class="card">
<Blockly />
</div>
</div>
</div>
</template>

<script>
import Configurator from './components/Configurator.vue';
//import Editor from '../components/Editor.vue';
import Timeline from './components/Timeline.vue';
import Blockly from './components/Blockly.vue';
import Demo from './components/Demo.vue';
import Team from './components/Team.vue';
import Assignment from './components/Assignment.vue';
import Chat from './components/Chat.vue';

export default {
  name: 'app',
  components: {
    Blockly,
    Demo,
    Timeline,
    Configurator,
    Team,
    Assignment,
    Chat
  },
  data(){
    return {
      configStage: {
        width:800,
        height:800
      },
      card: {
        width: 400,
        height:400
      },
      code: '',
      options: {
        media: 'media/'
      }
    }
  }
  }

</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width:100%;
  height:100%;
}
.row {
  height:50%;

}
.card {
  float:left;
  width: 50%;
  min-width:400px;
  height:400px;
  position: relative;
  float:left;
  display:block;
}


</style>
