<template>
<div style="padding:10px; width:45%; height:50%; overflow:auto; position: absolute;
bottom: 0;
left: 0;">
<ChatList :items="items" />
</div>
</template>

<script >

import ChatList from '../components/ChatList.vue'


export default {
  components: {
    ChatList
  },

  data() {
    return {
items:[{
id: 1,
      name: 'Jasper',
      message: 'Zijn jullie begonnen?',
      date: '6 aug 2021',
      time: '12:34'
      },
      {
id: 2,
      name: 'Tim',
      message: 'Bijna, ik lees de help nog even',
      date: '6 aug 2021',
      time: '12:36'
      }]
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
