import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto';
import Vuex from 'vuex'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import { EventDispatcher } from './components/globals.js';

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueKonva from 'vue-konva';

Vue.config.productionTip = false
  //Add unimported components to ignore list to prevent warnings.
Vue.config.ignoredElements = ['field', 'block', 'category', 'xml', 'mutation',
  'value', 'sep'
]


Vue.use(Vuex)
  /*
  const params = {
    "choiceType": "VanNaar",
    "title": "Van Hilversum naar Amsterdam",
    "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam door je juiste hwareware te koppelen en de juiste programmacode te schrijven.",
    "behaviour": {
      "enableChat": true,
      "enableTeam": true
    }
  };
  */

const params = {
  "choiceType": "Tussenstop",
  "title": "Van Hilversum naar Amsterdam",
  "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam maar stop ook even in Bussum.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }
};
/*
const params = {
  "choiceType": "Koe",
  "title": "Pas op voor de koe!",
  "description": "Laat de trein vanzelf rijden mara pas op voor los lopende koeien.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }
};
*/

const store = new Vuex.Store({
  state: {
    count: 0,
    code: '',
    timeline: [],
    params: params || []
  },
  mutations: {
    increment(state) {
        state.count++
      },
      code(state, n) {
        state.code = n
      }
  }
})

//Vue.use(BootstrapVue)
//Vue.use(IconsPlugin)

Vue.use(VueKonva);

new Vue({
  store: store,
  components: {
    App
  },
  render: h => h(App),
}).$mount(".h5p-train")
